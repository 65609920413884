<template>
  <h4 class="fst-italic">Santa Helena em Números </h4>
  <ol class="list-unstyled">
    <li v-for="estudosLateral in estudosLateralList" :key="estudosLateral.id">
      <p>
        <a :href="'/ler/estudo/' + estudosLateral.id" class="link-info"
          ><i class="bi bi-chevron-right"></i> {{estudosLateral.titulo}}</a
        >
      </p>
    </li>   
  </ol>
</template>
<script>
import { URL_BASE } from "../services/Commons";
import EstudosLateralService from "../services/estudo.service";
export default {
  name: "EstudosLateralComponent",  
 data: () => ({
    estudosLateralList: [],
    urlBase: "",
  }),
  mounted() {
    this.urlBase = URL_BASE;

    EstudosLateralService.getPaginacao(5).then((result) => {    
      if (result.status < 400) {
        this.estudosLateralList = result.data;
      }
    });
  },
};
</script>