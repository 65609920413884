<template>
  <h4 class="fst-italic">Destaques</h4>
  <ol class="list-unstyled">
    <li v-for="notasLateral in notasLateralList" :key="notasLateral.id">
      <p>
        <a :href="'/ler/nota/' + notasLateral.id" class="link-info"
          ><i class="bi bi-chevron-right"></i> {{notasLateral.titulo}}</a
        >
      </p>
    </li>    
  </ol>
</template>
<script>
import { URL_BASE } from "../services/Commons";
import NotasLateralService from "../services/notadiaria.service";
export default {
  name: "NotasLateralComponent",
  data: () => ({
    notasLateralList: [],
    urlBase: "",
  }),
  mounted() {
    this.urlBase = URL_BASE;

    NotasLateralService.getPaginacao(5).then((result) => {    
      if (result.status < 400) {
        this.notasLateralList = result.data;
      }
    });
  },
};
</script>