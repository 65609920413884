<template>
    <Estudo v-if="layoutPage == 'estudo'" :id="idObject"/>
    <Artigo v-if="layoutPage == 'artigo'" :id="idObject"/>
    <NotaDiaria v-if="layoutPage == 'nota'" :id="idObject"/>
    <Loading v-if="layoutPage == ''"/>
</template>

<script>
import Estudo from "./comp/Estudo.vue";
import Artigo from "./comp/Artigo.vue";
import NotaDiaria from "./comp/NotaDiaria.vue";
import Loading from "../../components/Loading.vue";
import { useRoute } from 'vue-router';
import { event } from 'vue-gtag';

export default {
  name: "Ler",
  components: {
    Estudo,
    Artigo,
    NotaDiaria,
    Loading,
  },
  data: () => ({
    idObject: null,
    layoutPage: "",
  }),
  mounted() {
    event('entrou_ler', { method: 'Google' })
    const route = useRoute();
    let layout = route.params.layout;
    let id = route.params.id;
    this.idObject = id;
    this.layoutPage = layout;
  },
};
</script>

<style>
</style>