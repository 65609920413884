<template>
  <h4 class="fst-italic">Observatório Social</h4>
  <ol class="list-unstyled">
     <li v-for="artigosLateral in artigosLateralList" :key="artigosLateral.id">
      <p>
        <a :href="'/ler/artigo/' + artigosLateral.id" class="link-info"
          ><i class="bi bi-chevron-right"></i> {{artigosLateral.titulo}}</a
        >
      </p>
    </li>   
  </ol>
</template>
<script>
import { URL_BASE } from "../services/Commons";
import ArtigosLateralService from "../services/artigo.service";
export default {
  name: "ArtigosLateralComponent",  
  data: () => ({
    artigosLateralList: [],
    urlBase: "",
  }),
  mounted() {
    this.urlBase = URL_BASE;

    ArtigosLateralService.getPaginacao(5).then((result) => {    
      if (result.status < 400) {
        this.artigosLateralList = result.data;
      }
    });
  },
};
</script>