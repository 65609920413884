<template>
  <section>
    <div class="container mx-auto text-center">
      <br/><br/><br/> <br/><br/>          
      <i class="fas fa-circle-notch fa-spin fa-8x text-rojo mt-10"></i>
      <span class=""><br />Carregando...</span>    
       <br/><br/><br/> <br/><br/><br/>  <br/><br/><br/> 
    </div>
  </section>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style>
</style>